<template>
    <baseform :title="'Edit Master PO'" :stand="'master'" />
</template>

<script>
import baseform from '@/views/pages/master_po/BaseForm.vue'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default {
    components: {
        baseform,
    },
    mounted() {
        setCurrentPageBreadcrumbs('Edit Master PO', [
            {
                title: "Procurement",
                link: '#'
            },
            {
                title: "Master PO",
                link: '/procurement/mstr-purchase-order'
            }
        ])
    },
    methods: {
        goBack() {
            return this.$router.go(-1)
        },
    }
}
</script>